@import "_variables.scss";
@import "_mixins.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/_mixins.scss";
@import "~font-awesome/scss/_variables.scss";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "_bootstrap-override.scss";
@import "_libs-override.scss";
@import "_select2-variables.scss";
//end custom libs

//everything below this line is required for essential styling
@import "_font.scss";
@import "_general.scss";
@import "_global-transitions.scss";
@import "_base.scss";
@import "_utils.scss";
@import "_print.scss";

body { font-size : 12px !important; font-weight: 400 !important }

/*shared classes*/
.float-left { float : left }
.float-right { float : right }
.clear { clear: both }
.overflow-hidden { overflow : hidden; }
.text-center { text-align : center }
.text-left { text-align : left }
.text-right { text-align : right }
.errors-list { font-size: 12px; margin-bottom: 5px; padding-left: 0;}
.errors-list li { color: #dd5826; list-style: outside none none; }
.pointer { cursor : pointer }
.centered { margin : 0px auto }
.pager.centered { width: 560px  }
.pager.centered > div > wj-combo-box { width: 70px }
a > span.glyphicon { padding: 1px 2px }

/*modal*/
modal-footer button { margin : 2px }

/*forms*/
.input-group wj-combo-box, .input-group wj-input-date, .input-group wj-multi-select { width : 100% }
.input-group input[type=radio] { margin : 2px 4px !important }
.form-horizontal .control-label { margin-bottom: 0; padding-top: 4px; }
.table, .wj-flexgrid { font-size : 12px; }

/*tree*/
.tree .wj-cell { background-color: white !important }

/*overrides stop make elements smaller*/

.page-controls { height : 30px }
.navbar { min-height : 30px }
.content { 
    padding: 45px 25px 25px;

    .btn, wj-input-date {
        margin: 0 1px;
    }
}
wj-popup .btn {
    margin: 0 1px;
}
.breadcrumb { margin-bottom : 3px }
/*.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {  padding-left : 7px; padding-right: 7px}*/
.navbar-header > .navbar-nav > li > a { padding: 4px 5px }
.navbar-form {  padding : 0px }
.navbar-collapse .nav.navbar-nav > li > a { padding : 5px }
.table th, .table td { padding : 3px }
.wj-input-group .wj-form-control {padding : 0px 2px }   
table tr td {
    .wj-content:not(.wj-dropdown-panel):not(.wj-listbox):not(.wj-flexgrid):not(.wj-calendar-outer) { border: none;  }
    .wj-input-group { min-height : 0px; left: -2px; top: -2px }
    .wj-input-group
    {
        .wj-btn { padding : 0px 2px; }
        .wj-form-control {  min-height : 0px; padding-right: 0px; padding-left: 0px  }
    }
    .wj-input-group-btn { width : 10px}
    .form-control { padding : 2px }
    wj-input-date, wj-combo-box,  wj-input-number { width : 100% }
}
legend { font-size: 12px }
.form-control { padding: 4px 12px; border-radius: 0px }
.input-group-btn > .btn { padding-bottom : 4px; padding-top : 4px }
.wj-form-control { font-weight: 400 }
table tr.footer { font-weight: bold; background-color: #eeeeee }
table.editable tr td { color:#aaa }
table.editable input { color: inherit }
.form-group { margin-top : 0px !important }
table td .wj-control[disabled] { background-color : white}
.wj-content .wj-input-group .wj-form-control { color: black }

/*pager*/
wj-collection-view-pager { width: 300px; margin : 0px auto ; display: block }

aol-map > div { background-color: #f5f5f5 }


/*validation*/
form.submitted
{
    .ng-invalid, .wj-control.ng-invalid input { border-color: brown !important; background-color: #ffefef !important  }    
}
.glyphicon-remove-sign{
    font-size:medium;
}

.pace.pace-active {
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
    position: fixed;
    z-index: 10000;
    width: 100%;
    pointer-events: all
}

main .col-md-2 .widget {
    min-height: 500px
}

main .col-md-2 .widget .search-bar input {
    min-width: 0;
}

.btn-info {
    background-color:  #008080 !important;
    border-color:  #008080 !important;
}

.btn-info:hover {
    background-color:  #004040 !important;
    border-color:  #004040 !important;
}

.btn-warning {
    background-color:  #dd5826 !important;
    border-color:  #dd5826 !important;
}

.btn-warning:hover {
    background-color:  #b4461c !important;
    border-color:  #b4461c !important;
}

perfect-scrollbar div[wj-part=root] { overflow: hidden !important}
perfect-scrollbar .tree .glyphicon-remove-sign { position: relative; right : 7px }

.tree div[wj-part=root] { overflow-x: hidden !important; overflow-y: auto }

.col-md-2 .container.ps-container { padding-left : 0px; padding-right: 0px }

daterange-picker { display: inline-block }
.daterange-picker-button { margin:0px 20px 0px 3px!important }