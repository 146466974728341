@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'),
  url(assets/fonts/google/open-sans-light.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'),
  url(assets/fonts/google/open-sans.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'),
  url(assets/fonts/google/open-sans-semibold.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url(assets/fonts/google/open-sans-bold.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'),
  url(assets/fonts/google/open-sans-light-italic.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
  url(assets/fonts/google/open-sans-italic.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: local('Open Sans Semibold Italic'), local('OpenSans-SemiboldItalic'),
  url(assets/fonts/google/open-sans-semibold-italic.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
  url(assets/fonts/google/open-sans-bold-italic.woff) format('woff');
}

// Glyphicons

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('assets/fonts/glyphicons/glyphicons-halflings-regular.eot');
  src: url('assets/fonts/glyphicons/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),
       url('assets/fonts/glyphicons/glyphicons-halflings-regular.woff2') format('woff2'),
       url('assets/fonts/glyphicons/glyphicons-halflings-regular.woff') format('woff'),
       url('assets/fonts/glyphicons/glyphicons-halflings-regular.ttf') format('truetype'),
       url('assets/fonts/glyphicons/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}
//@font-face {
//  font-family: 'Roboto';
//  font-style: normal;
//  font-weight: 300;
//  src: local('Roboto Light'), local('Roboto-Light'), url(fonts/google/roboto-light.woff) format('woff');
//}
//@font-face {
//  font-family: 'Roboto';
//  font-style: normal;
//  font-weight: 400;
//  src: local('Roboto Regular'), local('Roboto-Regular'), url(fonts/google/roboto-regular.woff) format('woff');
//}
//@font-face {
//  font-family: 'Roboto';
//  font-style: normal;
//  font-weight: 500;
//  src: local('Roboto Medium'), local('Roboto-Medium'), url(fonts/google/roboto-medium.woff) format('woff');
//}
//@font-face {
//  font-family: 'Roboto';
//  font-style: normal;
//  font-weight: 700;
//  src: local('Roboto Bold'), local('Roboto-Bold'), url(fonts/google/roboto-bold.woff) format('woff');
//}
//@font-face {
//  font-family: 'Roboto';
//  font-style: normal;
//  font-weight: 900;
//  src: local('Roboto Black'), local('Roboto-Black'), url(fonts/google/roboto-black.woff) format('woff');
//}
//@font-face {
//  font-family: 'Roboto';
//  font-style: italic;
//  font-weight: 400;
//  src: local('Roboto Italic'), local('Roboto-Italic'), url(fonts/google/roboto-italic.woff) format('woff');
//}
